<template>
  <span>{{ field.options[item.params[field.name]] }}</span>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    },
    field: {
      type: Object
    }
  },
}
</script>
