import StringField from './fields/viewable/string'
import DateField from './fields/viewable/date'
import PhoneField from './fields/viewable/phone'
import CalculatedField from './fields/viewable/calculated'
import SelectField from './fields/viewable/select'

const viewableFields = {
  StringField,
  DateField,
  PhoneField,
  CalculatedField,
  SelectField,
}

export {viewableFields}
