<template>
  <div class="table-responsive">
    <table class="table card-table">
      <tbody>
      <tr class="border-bottom">
        <th v-for="(field,index) in fields" :key="index">
          {{ field.name }}
        </th>
        <th>
          Actions
        </th>
      </tr>
      <tr v-for="item in items" :key="item.id" class="border-bottom">
        <td v-for="(field,fieldIndex) in fields" :key="fieldIndex">
          <component :is="field.type + '-field'" :item="item" :field="field"></component>
        </td>
        <td>
          <router-link :to="{name: 'crud-item', params: {module: module.alias, id: item.id}}">
            <sms-icon name="fa fa-edit" color="green"></sms-icon>
          </router-link>
        </td>
      </tr>
      </tbody></table>
  </div>
</template>

<script>
import SmsIcon from "@/views/components/Icon";
import {viewableFields} from "@/views/crud/fields";

export default {
  props: {
    module: {
      type: Object,
    },
    items: {
      type: Array,
    },
    view: {
      type: Object,
    },
  },
  components: {
    SmsIcon,
    ...viewableFields,
  },
  data() {
    return {}
  },
  computed: {
    fields() {
      return this.module.fields.filter(x => this.view.fields.includes(x.name))
    }
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {},
}
</script>
