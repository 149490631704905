<template>
  <sms-content :title="module.label">
    <sms-grid-row>
      <sms-card :body-padding="2">
        Views:
        <select @change="toView" v-model="currentViewName">
          <option v-for="view in module.views" :key="view.name" :value="view.name">{{ view.label }}</option>
        </select>
      </sms-card>

      <sms-card :body-padding="0">
        <component :is="currentView.type + '-view'" :module="module" :view="currentView" :items="items"></component>
      </sms-card>
    </sms-grid-row>

    <template v-slot:tools>
      <a @click.prevent="createItem" href="#" class="btn btn-primary btn-icon btn-sm text-white mr-2">
        <span>
          <i class="fe fe-plus"></i>
        </span> Create item
      </a>
    </template>
  </sms-content>
</template>

<script>
import SmsContent from "@/views/partials/Content"
import SmsGridRow from "@/views/components/GridRow";
import SmsCard from "@/views/components/Card";
import axios from "@/plugins/axios";
import {views} from "@/views/crud/views";

export default {
  name: 'CrudList',
  components: {
    SmsCard,
    SmsGridRow,
    SmsContent,
    ...views,
  },
  data() {
    return {
      items: [],
      currentViewName: null,
    }
  },
  computed: {
    module() {
      return this.$store.getters["crud/currentModule"]
    },
    currentView() {
      return this.currentViewName
        ? this.module.views.filter(x => x.name === this.currentViewName)[0]
        : this.module.views.filter(x => x.name === this.module.default_view)[0]
    },
  },
  mounted() {
    this.loadList()

    if (this.$route.params.view) {
      this.currentViewName = this.$route.params.view
    }
  },
  methods: {
    loadList() {
      axios.get('/crud/' + this.module.alias).then(response => {
        this.items = response.data.items
      })
    },
    createItem() {
      axios.post('/crud/' + this.module.alias).then(response => {
        this.$router.push({name: 'crud-item', params: {id: response.data.item_id}})
      })
    },
    toView() {
      this.$router.push({name: 'crud-view', params: {module: this.module.alias, view: this.currentViewName}})
    }
  },
}
</script>
