<template>
  <span>{{ item.params[field.name] }}</span>
</template>

<script>
export default {
  props: {
    item: {
      type: Object
    },
    field: {
      type: Object
    }
  },
  components: {},
  data() {
    return {}
  },
  computed: {},
  mounted() {
  },
  destroyed() {
  },
  methods: {},
}
</script>
