<template>
  <div class="list-view">
    <div v-for="item in items" :key="item.id" class="list-group">
      <a class="list-group-item list-group-item-action flex-column align-items-start active">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">
            <router-link :to="{name: 'crud-item', params: {module: module.alias, id: item.id}}">{{ item.title }}</router-link>
          </h5>
          <small class="text-muted">3 days ago</small>
        </div>
<!--        <p class="mb-1">Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius blandit.</p>-->
<!--        <small class="text-muted">Donec id elit non mi porta.</small>-->
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    module: {
      type: Object,
    },
    items: {
      type: Array,
    },
    view: {
      type: Object,
    },
  },
  data() {
    return {}
  },
  computed: {},
  mounted() {
  },
  destroyed() {
  },
  methods: {},
}
</script>
